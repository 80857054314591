import React, { Component, lazy, Suspense } from 'react';
import {
  IonApp,
  IonSpinner,
} from '@ionic/react';

import './SharedCode/theme/variables.css';
import './App.css';
import { setupIonicReact } from '@ionic/react';

setupIonicReact({
  mode: 'md'
});

// import SharedApp from './SharedCode/SharedApp';
const SharedApp = lazy(() => import("./SharedCode/SharedApp"));

type Props = {}
type State = {

}

class App extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {
    const app = 'iCPG';

    return (
      <IonApp>
        <Suspense fallback={
          <div className="loadingScreen">
            <div className="loadingScreenText">
              Loading
            </div>
            <IonSpinner className="loadingScreenSpinner" />
          </div>
        }>
          <SharedApp
            app={app}
          />
        </Suspense>
      </IonApp>
    )
  }
};

export default App;
